import { ChainId, CurrencyAmount, ETHER, JSBI, MCurrencyAmount, Native, TokenAmount, mCurrency } from '@abstra-dex/sdk'
import { MIN_BNB } from '../config/constants'

/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 */
export function maxAmountSpend(currencyAmount?: CurrencyAmount): CurrencyAmount | undefined {
  if (!currencyAmount) return undefined
  if (currencyAmount.currency === ETHER) {
    if (JSBI.greaterThan(currencyAmount.raw, MIN_BNB)) {
      return CurrencyAmount.ether(JSBI.subtract(currencyAmount.raw, MIN_BNB))
    }
    return CurrencyAmount.ether(JSBI.BigInt(0))
  }
  return currencyAmount
}

export function maxAmountCrossSpend(
  currencyAmount: TokenAmount | MCurrencyAmount<mCurrency>,
  chainId: ChainId,
  fee?: TokenAmount | MCurrencyAmount<mCurrency>,
): TokenAmount | MCurrencyAmount<mCurrency> {
  try {
    if (!currencyAmount) return null

    if (fee) {
      if (JSBI.greaterThan(fee.raw, currencyAmount.raw)) return currencyAmount

      const amountSpend = JSBI.subtract(currencyAmount.raw, fee.raw).toString()

      if (currencyAmount instanceof MCurrencyAmount) {
        return MCurrencyAmount.fromRawAmount(currencyAmount.currency, amountSpend)
      }
      if (currencyAmount instanceof TokenAmount) {
        return new TokenAmount(currencyAmount.token, amountSpend)
      }
    } else if (currencyAmount.currency === Native.onChain(chainId) && JSBI.greaterThan(currencyAmount.raw, MIN_BNB)) {
      const amountSpend = JSBI.subtract(currencyAmount.raw, MIN_BNB).toString()
      return MCurrencyAmount.fromRawAmount(Native.onChain(chainId), amountSpend)
    }

    return currencyAmount
  } catch (e) {
    return currencyAmount
  }
}
